// To quickly centre a block element
@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

/*
 * Usage Example:
 *
 * div::after {
 *     @include pseudo;
 *     top: -1rem; left: -1rem;
 *     width: 1rem; height: 1rem;
 * }
 */
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

/*
 * Usage Example:
 *
 * div {
 *     @include responsive-ratio(16,9);
 * }
 */
@mixin responsive-ratio($x, $y, $pseudo: false) {
  $padding: unquote(($y / $x ) * 100 + '%');

  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  }

  @else {
    padding-top: $padding;
  }
}

/*
 * This mixin takes all the hassle out of creating that triangle you'll see coming out of most traditional tooltips,
 * all without images, you just specify it's colour, how big you want it, the direction it's going to come out of your element and you're done!
 */
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;

  @if $round {
    border-radius: 3px;
  }

  @if $direction ==down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  }

  @else if $direction ==up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  }

  @else if $direction ==right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  }

  @else if $direction ==left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@mixin font-source-sans($size: false, $colour: false, $weight: false, $lh: false) {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;

  @if $size {
    font-size: $size;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh;
  }
}


@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

/*
 * Usage Example:
 *
 * .site-header {
 *    padding: 2rem;
 *    font-size: 1.8rem;
 *    @include mq('tablet-wide') {
 *        padding-top: 4rem;
 *        font-size: 2.4rem;
 *    }
 * }
 */
$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type ==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

/*
 * .site-header {
 *    z-index: z('site-header');
 * }
 */
@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name))+1;
  }

  @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null;
  }
}

$z-indexes: (
  "outdated-browser",
  "modal",
  "site-header",
  "page-wrapper",
  "site-footer"
);

// Simple and effective for when you need to trigger hardware acceleration for some animation,
// keeping everything fast, slick and flicker-free.
@mixin hardware($backface: true, $perspective: 1000) {
  @if $backface {
    backface-visibility: hidden;
  }

  perspective: $perspective;
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
 * Source: http://thesassway.com/intermediate/mixins-for-semi-transparent-colors
 *
 * Usage Example:
 *
 * .button { @include alpha-attribute('background-color', rgba(black, 0.5), white); }
 */
@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}



@mixin responsive1650 {
  @media (max-width: 1650px) {
    @content;
  }
}

@mixin responsive1199 {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin responsive991 {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin responsive576 {
  @media (max-width: 576px) {
    @content;
  }
}

.mobile {
  display: none !important;
}

.desktop {
  display: flex;
}

@include responsive576 {
  .mobile {
    display: flex !important;
    align-items: center;
  }

  .desktop {
    display: none !important;
  }
}