/*****************************************************
 * node_modules imports
 *****************************************************/
@import 'node_modules/bootstrap/scss/bootstrap.scss';

/*****************************************************
 * project imports
 *****************************************************/
@import '../assets/reset.scss';
@import '../assets/font/stylesheet.css';
@import 'variables';
@import 'mixins';

/*****************************************************
 * styles
 *****************************************************/


// body {
//   background-color: $mainBgColor;
//   color: $mainTextColor;
// }

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

.overflow-auto {
  overflow: auto !important;
}

.block-description,
.info-block {
  // text-align: center;
  color: #000;
  font-size: 12rem;
  line-height: 1.25;

  #text {
    color: #000;
    font-size: 12rem;
    line-height: 1.25;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000;
    font-size: 16rem;
    font-weight: 600;
    margin: 20rem 0;
    text-align: center;
  }

  p {
    text-align: left;
    margin-bottom: 14rem;
  }

  ul,
  ol {
    padding-left: 20rem;
    margin-bottom: 20rem;
    text-align: left;
    color: #000;
    font-size: 12rem;
    line-height: 1.25;
  }

  ul {
    list-style: disc;
  }
}


html {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  font-size: 1px;

  @media screen and (max-width: 1650px) {
    font-size: 1px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 0.85px;
  }

  @media screen and (max-width: 1366px) {
    font-size: 0.8px;
  }

  @media screen and (max-width: 576px) {
    font-size: 0.94px;
  }

  @media screen and (max-width: 375px) {
    font-size: .8px;
  }
}

body {
  font-family: 'Visa Dialect';
  font-size: 16rem;
  font-weight: 400;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(0deg, rgba(33, 38, 51, 0.50) 0%, rgba(33, 38, 51, 0.50) 100%), #1F1F1F;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #000;
  min-height: -webkit-fill-available;
}

html,
body,
app-root,
app-game {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.engine-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  #renderCanvas {
    width: 100%;
    height: 100%;
    touch-action: none;
  }

  #renderCanvas:focus {
    outline: none;
  }
}

.ui-wrapper {
  position: absolute;

  * {
    z-index: 10;
  }
}

a {
  text-decoration: none !important;
  cursor: pointer;
}


.btn {
  border-radius: 39rem;
  border: 3px solid #FCC015;
  background: #FCC015;
  width: 100%;
  color: #1434CB;
  font-size: 18rem;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  &.transparent {
    background: #1434CB;
    color: #fff;
  }
}


img,
video,
.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

video {
  object-fit: cover;
}

.modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.70);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.close-btn {
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(25%, -30%);
  background: #1434CB;
  padding: 12rem;

  @include responsive576 {
    width: 48rem;
    height: 48rem;
  }
}

.swiper,
.swiper-wrapper,
.swiper-slide {
  height: 100%;
}

.swiper-slide {
  border-radius: 14rem;
  background: #F9F9FA;
  box-shadow: 10px 10px 0px 0px rgba(192, 204, 227, 0.50), 2px 2px 10px 0px rgba(0, 0, 0, 0.20);
  height: 100%;
  // width: 220rem;
  overflow: hidden;

  .slide {
    display: flex;
    flex-direction: column;
  }
}


::-webkit-scrollbar {
  width: 4rem;
  border-radius: 14rem;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 14rem;
  background: #DCE1F7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 14rem;
  background: #FCC015;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(0deg, #1434CB 0%, #1434CB 100%), linear-gradient(51deg, rgba(226, 232, 242, 0.53) 3.22%, rgba(226, 232, 242, 0.48) 97.12%);
}