@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-Regular.eot');
    src: local('Visa Dialect Regular'), local('VisaDialect-Regular'),
        url('VisaDialect-Regular.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-Regular.woff2') format('woff2'),
        url('./VisaDialect-Regular.woff') format('woff'),
        url('VisaDialect-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-Medium.eot');
    src: local('Visa Dialect Medium'), local('VisaDialect-Medium'),
        url('VisaDialect-Medium.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-Medium.woff2') format('woff2'),
        url('VisaDialect-Medium.woff') format('woff'),
        url('VisaDialect-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-Bold.eot');
    src: local('Visa Dialect Bold'), local('VisaDialect-Bold'),
        url('VisaDialect-Bold.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-Bold.woff2') format('woff2'),
        url('VisaDialect-Bold.woff') format('woff'),
        url('VisaDialect-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-BoldItalic.eot');
    src: local('Visa Dialect Bold Italic'), local('VisaDialect-BoldItalic'),
        url('VisaDialect-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-BoldItalic.woff2') format('woff2'),
        url('VisaDialect-BoldItalic.woff') format('woff'),
        url('VisaDialect-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-Light.eot');
    src: local('Visa Dialect Light'), local('VisaDialect-Light'),
        url('VisaDialect-Light.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-Light.woff2') format('woff2'),
        url('VisaDialect-Light.woff') format('woff'),
        url('VisaDialect-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-SemiboldItalic.eot');
    src: local('Visa Dialect SemiBold Italic'), local('VisaDialect-SemiboldItalic'),
        url('VisaDialect-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-SemiboldItalic.woff2') format('woff2'),
        url('VisaDialect-SemiboldItalic.woff') format('woff'),
        url('VisaDialect-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-RegularItalic.eot');
    src: local('Visa Dialect Regular Italic'), local('VisaDialect-RegularItalic'),
        url('VisaDialect-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-RegularItalic.woff2') format('woff2'),
        url('VisaDialect-RegularItalic.woff') format('woff'),
        url('VisaDialect-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-Semibold.eot');
    src: local('Visa Dialect SemiBold'), local('VisaDialect-Semibold'),
        url('VisaDialect-Semibold.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-Semibold.woff2') format('woff2'),
        url('VisaDialect-Semibold.woff') format('woff'),
        url('VisaDialect-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-MediumItalic.eot');
    src: local('Visa Dialect Medium Italic'), local('VisaDialect-MediumItalic'),
        url('VisaDialect-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-MediumItalic.woff2') format('woff2'),
        url('VisaDialect-MediumItalic.woff') format('woff'),
        url('VisaDialect-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Visa Dialect';
    src: url('VisaDialect-LightItalic.eot');
    src: local('Visa Dialect Light Italic'), local('VisaDialect-LightItalic'),
        url('VisaDialect-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('VisaDialect-LightItalic.woff2') format('woff2'),
        url('VisaDialect-LightItalic.woff') format('woff'),
        url('VisaDialect-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

